
import { Component, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { Table as ElTable, TableColumn as ElTableColumn} from 'element-ui';
import VueSlider from 'vue-slider-component';
import { Splide, SplideSlide} from '@splidejs/vue-splide';
import LandingFooter from '@/components/Footer/LandingFooter.vue';
import '@/assets/landing/assets/web/assets/mobirise-icons2/mobirise2.css';
import '@/assets/landing/assets/bootstrap/css/bootstrap.min.css';
import '@/assets/landing/assets/bootstrap/css/bootstrap-grid.min.css';
import '@/assets/landing/assets/bootstrap/css/bootstrap-reboot.min.css';
import '@/assets/landing/assets/tether/tether.min.css';
import '@/assets/landing/assets/dropdown/css/style.css';
import '@/assets/landing/assets/animatecss/animate.min.css';
import '@/assets/landing/assets/theme/css/style.css';
import '@/assets/landing/assets/mobirise/css/mbr-additional.css';

@Component({
  components: {
    Bar,
    ElTable,
    ElTableColumn,
    VueSlider,
    Splide,
    SplideSlide,
    LandingFooter,
  },
})
export default class TermsStatic extends Vue {
  public lang = 'en';

  public beforeRouteEnter(to, from, next) {
    if (window.location.host === 'apikey.nl') {
      next('/');
    } else {
      next();
    }
  }
}

